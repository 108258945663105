import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Fade } from "react-awesome-reveal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BText } from "../components/chakra/BText"

function ProjectPage(props) {
  const { data, location } = props
  const { site, strapiProjects } = data || {}
  const { siteMetadata } = site || {}
  const { title, strapiUrl, siteUrl } = siteMetadata || {}
  const {
    sections,
    coverImage,
    backgroundColor,
    textColor,
    logo,
    seo,
    shortDescription,
    coverParagraph,
    hamburgerBackgroundColor,
  } = strapiProjects || {}
  const { localFile } = coverImage || {}

  const footerSection = sections[sections.length - 1]

  const coverGatsbyImage = getImage(localFile)
  return (
    <Layout
      location={location}
      title={title}
      backgroundColor={`${backgroundColor.color}`}
      hamburgerColor={`${hamburgerBackgroundColor.color}`}
      logo={`${strapiUrl}${logo}`}
      duration={"0"}
      delay={"0"}
      footerBackgroundColor={`${footerSection.backgroundColor.color}`}
      footerColor={`${footerSection.textColor.color}`}
      textColor={`${textColor.color}`}
      siteUrl={`${siteUrl}`}
    >
      <Seo title={seo.metaTitle} description={seo.metaDescription} />
      <section
        id={"contact-hero"}
        style={{
          backgroundColor: `${backgroundColor.color}`,
          color: `${textColor.color}`,
        }}
      >
        <div id={"contact-hero-spacer-top"} />
        <div className={"max-width"}>
          <Fade duration={1000} delay={200} triggerOnce={true}>
            <BText
              as={"h1"}
              id={"contact-hero-title"}
              style={{
                color: `${textColor.color}`,
              }}
            >
              {strapiProjects.title}
            </BText>
            <br />
            <BText
              as={"h1"}
              id={"contact-hero-subtitle"}
              style={{
                color: `${textColor.color}`,
              }}
            >
              {shortDescription}
            </BText>
            <br />
            <BText
              as={"h1"}
              id={"contact-hero-subtitle-2"}
              style={{
                color: `${textColor.color}`,
              }}
            >
              {coverParagraph}
            </BText>
          </Fade>
        </div>
        <div id={"contact-hero-spacer-bottom"} />
      </section>
      <div className={"contact-hero-main-image max-width"}>
        <GatsbyImage image={coverGatsbyImage} alt={"Image"} />
      </div>
      <div id={"content"}>
        {sections.map(
          ({ backgroundColor, textColor, Heading, Paragraph, Image }) => {
            return (
              <div
                style={{
                  backgroundColor: `${backgroundColor.color}`,
                  color: `${textColor.color} !important`,
                }}
              >
                <section
                  className={"sections"}
                  style={{
                    backgroundColor: `${backgroundColor.color}`,
                    color: `${textColor.color} !important`,
                    paddingTop: "25vh",
                    paddingBottom: "25vh",
                  }}
                >
                  <div id={"contact-hero-spacer-top"} />
                  <div className={"max-width"}>
                    <Fade duration={1000} delay={500} triggerOnce={true}>
                      <BText
                        as={"h1"}
                        id={"contact-hero-subtitle"}
                        style={{
                          color: `${textColor.color}`,
                        }}
                      >
                        {Heading}
                      </BText>
                      <br />
                      <BText
                        as={"h1"}
                        id={"contact-hero-subtitle-2"}
                        style={{
                          color: `${textColor.color}`,
                        }}
                      >
                        {Paragraph}
                      </BText>
                    </Fade>
                  </div>
                </section>
                {Image?.localFile && (
                  <div className={"contact-hero-image max-width"}>
                    <GatsbyImage
                      style={{ margin: "auto" }}
                      image={getImage(Image.localFile)}
                      alt={Image.alternativeText}
                    />
                  </div>
                )}
              </div>
            )
          }
        )}
      </div>
    </Layout>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        strapiUrl
        siteUrl
      }
    }
    strapiProjects(slug: { eq: $slug }) {
      id
      hamburgerBackgroundColor {
        color
      }
      shortDescription
      coverParagraph
      coverColor {
        color
      }
      backgroundColor {
        color
      }
      coverImage {
        url
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(height: 600, placeholder: BLURRED)
          }
        }
      }
      coverTextColor {
        color
      }
      title
      thumbnail {
        alternativeText
        url
      }
      slug
      seo {
        metaDescription
        metaTitle
        metaImage {
          url
        }
      }
      logo
      textColor {
        color
      }
      sections {
        Heading
        Paragraph
        id
        textColor {
          color
        }
        backgroundColor {
          color
        }
        Image {
          url
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(height: 600, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
